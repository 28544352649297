import axios from 'axios'

// create an axios instance
const headers = {
  'Content-Type': 'application/json'
}

const service = axios.create({
  baseURL: '/api',
  headers: headers
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (window.localStorage.getItem('user-token')) {
      config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('user-token')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if ((response.data.code === 401 || response.data.code === 403) && localStorage.getItem('user-token')) {
      localStorage.removeItem('user')
      localStorage.removeItem('user-token')
      window.location.replace(`${window.location.origin}/user/login`)
      return
    }
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    if ((error.response.data.code === 401 || error.response.data.code === 403) && localStorage.getItem('user-token')) {
      localStorage.removeItem('user')
      localStorage.removeItem('user-token')
      window.location.replace(`${window.location.origin}/user/login`)
      return
    }
    return Promise.reject(error)
  }
)

export default service
